<template>
  <div class="row mx-0">
    <div class="col-11 col-lg-10 mx-auto px-0">
      <div class="card p-3">
        <h3 class="text-center mb-3"> {{ ($route.path.slice(-5)=='criar')? 'Criar':'Editar' }} Notícias</h3>
        <div class="row mx-0 my-1">
          <div class="col-12 col-lg ps-0 pe-1">
            <input v-model="form.post_title" type="text" class="form-control" placeholder="Título" />
          </div>
          <div class="col-12 col-lg-auto ps-1 pe-0">
            <input v-model="form.post_date" type="datetime-local" class="form-control" />
          </div>
          <div class="col-12 col-lg-auto ps-1 pe-0">
            <label class="small" for="check">Publicado?</label>
            <input v-model="form.post_status" id="check" type="checkbox" class="form-control" />
          </div>
        </div>
        <vue-editor id="editor" v-model="form.post_content" />
        <div class="row mx-0 justify-content-around">
          <button @click="$router.push('/noticias/posts')" :disabled="loading"
            class="col-auto my-3 btn btn-default border">Voltar</button>
          <button @click="action()" :disabled="loading" class="col-auto my-3 btn btn-success">Salvar
            <b-icon v-if="loading" icon="slack" class="mx-1" animation="spin" /></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loading: false,
        form: {
          post_title: '',
          post_date: '',
          post_content: '',
          post_status: false
        },
      }
    },
    beforeMount() {
      if (this.$route.path == '/noticias/posts/editar') {
        if (!this.$store.state.news.edit_post) return this.$router.push('/noticias/posts');
        this.form.post_title = this.$store.state.news.edit_post.post_title;
        this.form.post_date = this.ftdate(this.$store.state.news.edit_post.post_date);
        this.form.post_content = this.$store.state.news.edit_post.post_content;
        this.form.post_status = (this.$store.state.news.edit_post.post_status == 'publish');
      } else this.form.post_date = new Date()
    },
    methods: {
      ftdate(payload) {
        if (payload)
          return `${payload.slice(6,10)}-${payload.slice(3,5)}-${payload.slice(0,2)}T${payload.slice(-8)}`
      },
      action() {
        (this.$route.path == '/noticias/posts/criar') ? this.setCreatePost(): this.setEditPost();
      },
      setEditPost() {
        this.loading = true
        this.$store.dispatch('setEditPost', this.form)
          .then((res) => [this.swal(true, res.mensagem), this.$router.push('/noticias/posts')])
          .catch((error) => {
            this.swal(false, error.data.mensagem, error);
            if (error.status == 401) this.$emit('Auth');
          })
          .finally(() => this.loading = false)
      },
      setCreatePost() {
        this.loading = true
        this.$store.dispatch('setCreatePost', this.form)
          .then((res) => [this.swal(true, res.mensagem), this.$router.push('/noticias/posts')])
          .catch((error) => {
            this.swal(false, error.data.mensagem, error);
            if (error.status == 401) this.$emit('Auth');
          })
          .finally(() => this.loading = false)
      }
    }
  }
</script>

<style scoped>

</style>

<style>
  #editor {
    height: calc(100vh - 380px);
  }
</style>